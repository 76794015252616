<link href="https://cdn.jsdelivr.net/npm/bootstrap-icons/font/bootstrap-icons.css" rel="stylesheet">
<!-- <app-loading-element *ngIf="loading" class="container-fluido componentBelow"></app-loading-element> -->
<section *ngIf="!loading">

<div class="container-fluid mt-4">
  <h1 class="mb-4">
    User Management
  </h1>

  <div class="mb-3">
    <button class="btn btn-primary" (click)="deleteSelectedUsers()">Delete Selected Users</button>
  </div>

  <div class="row mb-3">
    <div class="col-md-6">
      <input
        type="text"
        class="form-control search-input"
        placeholder="Search by username, email, first name, or last name..."
        [(ngModel)]="searchQuery"
      />
      <button class="form-control search-button" (click)="searchUser()">Search</button>
    </div>

    <div class="col-md-4 offset-md-2">
      <select
        class="form-select"
        [(ngModel)]="sortField"
        (change)="changeFilter()">
        <option value="username">Username</option>
        <option value="email">Email</option>
        <option value="given_name">First Name</option>
        <option value="family_name">Last Name</option>
        <option value="confirmed">Confirmed Status</option>
        <option value="force_change_password">Change Password Status</option>
      </select>
    </div>
  </div>

  <div class="table-responsive">
    <table class="table table-hover">
      <thead class="table-light">
        <tr>
          <!-- "Select All" Checkbox -->
          <th>
            <input
              type="checkbox"
              [checked]="allSelected"
              (change)="toggleSelectAll()"
            />
          </th>
          <th (click)="setSortField('username')">
            Username <i [class]="'bi bi-sort-' + (sortField === 'username' ? sortOrder : 'up')"></i>
          </th>
          <th (click)="setSortField('email')">
            Email <i [class]="'bi bi-sort-' + (sortField === 'email' ? sortOrder : 'up')"></i>
          </th>
          <th (click)="setSortField('given_name')">
            First Name <i [class]="'bi bi-sort-' + (sortField === 'given_name' ? sortOrder : 'up')"></i>
          </th>
          <th (click)="setSortField('family_name')">
            Last Name <i [class]="'bi bi-sort-' + (sortField === 'family_name' ? sortOrder : 'up')"></i>
          </th>
          <th (click)="setSortField('user_status')">
            Status <i [class]="'bi bi-sort-' + (sortField === 'user_status' ? sortOrder : 'up')"></i>
          </th>
          <th>
            Location <i [class]="'bi'"></i>
          </th>
        </tr>
      </thead>
      <tbody style="max-height: 70vh; overflow-y: auto; border: 1px solid #dee2e6;">
        <tr *ngFor="let user of this.cognito.cognitoUsersArray; trackBy: trackByUserId">
          <!-- Checkbox for each user -->
          <td class="td">
            <input
              type="checkbox"
              [checked]="selectedUsers.includes(user)"
              (change)="toggleUserSelection(user)"
            />
          </td>
          <td class="td">{{ user.username }}</td>
          <td class="td">{{ user.email }}</td>
          <td class="td">{{ user.given_name }}</td>
          <td class="td">{{ user.family_name }}</td>
          <td class="td">{{ user.user_status }}</td>
          <td class="td-location td">
            <a class="a-location" (click)="triggerLocationDropbox(user.email, user.syuser?.location);">{{ user.syuser?.location }}</a>
            <div class="div-select" *ngIf="this.activeUserEmail === user.email">
              <select class="form-select" *ngIf="!this.displayLocationMessage" [(ngModel)]="selectedLocation" (change)="changeUserLocation(user);">
                <option value="NA">NA</option>
                <option value="EUR">EUR</option>
                <option value="CH">CH</option>
                <option value="DEV">DEV</option>
                <option value="QAS">QAS</option>
              </select>
              <div *ngIf="this.displayLocationMessage">
                {{ this.apiService.locationUpdateMessage | translate }}
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="this.cognito.cognitoUsersArray.length === 0" class="mt-3">
    <p class="text-center">No users found.</p>
  </div>
</div>
</section>
