import { Component } from '@angular/core';
import { CognitoService, CognitoUser } from '../service/cognito.service';
import { ApiService } from '../service/api.service';
import { DomSanitizer } from '@angular/platform-browser';import { Router } from '@angular/router';
;
@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css']
})
export class ManagementComponent {
  public users: CognitoUser[] = [];
  public visibleUsers: CognitoUser[] = [];
  public searchQuery: string = '';
  public sortField: string = 'username'; // Ensuring sortField is one of the keys of CognitoUser
  public sortOrder: 'up' | 'down' = 'up';
  private currentPage: number = 0; // Current page number for pagination
  private pageSize: number = 20; // Number of users to load per request
  public loading: boolean = false;

  public activeUserEmail: string = '';
  public selectedLocation: string = '';

  // Property to hold the selected user
  public selectedUser: CognitoUser | null = null;

  // Properties for multiple selection
  public selectedUsers: CognitoUser[] = [];
  public allSelected: boolean = false;

  public displayLocationMessage: boolean = false;


  constructor(
    public cognito: CognitoService,
    public apiService: ApiService,
    public sanitizer: DomSanitizer,
    private router: Router
  ) {}

  async ngOnInit() {
    // If the syuser array from DB is empty, it will go implement it
    if(this.cognito.syusers.length === 0){
      // Implement the SYUSER array with users from the DB
      this.cognito.syusers = await this.apiService.getSYUSERS().then((res)=>{
        return res;
      });
    }

    // Get current cognito user infos then associate the currentuser to his DB infos
    const currentUserCognito = await this.cognito.getUser();
    const currentUserDB = this.cognito.syusers.find((user: any) => user.userid === currentUserCognito.username);

    // If the current user is not an admin user, redirect him to the login page
    if(currentUserDB?.syadmin !== 'T'){
      this.router.navigate(['/login']);
    }

    this.loading = false;
  }

  // Function used when user click on the search button
  async searchUser(){
    // Reset the cognito user array at nothing
    this.cognito.cognitoUsersArray = [];

    // Call the function that made the call to cognito with the proper field
    await this.cognito.searchUser(this.sortField, this.searchQuery);
  }

  // Function called when user change the filter select
  changeFilter(){
    // Reset congito user array and the search field
    this.searchQuery = '';
    this.cognito.cognitoUsersArray = [];

    // Made a call to made a search for all confrmed of not confirmedd in cgnito user pool if the selected field are confirmed or force change password
    if(this.sortField === 'confirmed' || this.sortField === 'force_change_password'){
      this.cognito.searchUser(this.sortField);
    }
  }

  // Function used when user click on the arrows at the right of collumn's name
  async sortUsers() {
    // Sort cognito user array
    this.cognito.cognitoUsersArray.sort((a: any, b: any) => {
      const fieldA = (a[this.sortField] || '').toString().toLowerCase();
      const fieldB = (b[this.sortField] || '').toString().toLowerCase();

      if (fieldA < fieldB) return this.sortOrder === 'up' ? -1 : 1;
      if (fieldA > fieldB) return this.sortOrder === 'up' ? 1 : -1;
      return 0;
    });
  }

  // Function called to set the sor field depending on witch column the user clicked
  async setSortField(field: keyof CognitoUser) {
    this.loading = true;
    if (this.sortField === field) {
      // Toggle sort order if the same field is clicked
      this.sortOrder = this.sortOrder === 'up' ? 'down' : 'up';
    } else {
      this.sortField = field;
      this.sortOrder = 'up'; // Default to ascending order for new fields
    }
    await this.sortUsers();
    setTimeout(() => {
      this.loading = false;
    }, 1500);
  }

  // Function used when user click on the check box at the top-left of the table
  toggleSelectAll() {
    if (this.allSelected) {
      // If allSelected is true, deselect all users
      this.selectedUsers = [];
      this.allSelected = false; // Explicitly set to false
    } else {
      // If allSelected is false, select all visible users
      this.selectedUsers = [...this.cognito.cognitoUsersArray];
      this.allSelected = true; // Explicitly set to true
    }
  }

  // Method to toggle selection of individual users
  toggleUserSelection(user: CognitoUser) {
    const index = this.selectedUsers.findIndex(selected => selected.username === user.username);
    if (index === -1) {
      this.selectedUsers.push(user);
    } else {
      this.selectedUsers.splice(index, 1);
    }
    // Update "Select All" state
    this.allSelected = this.selectedUsers.length === this.visibleUsers.length;
  }

  // Method for tracking users in *ngFor
  trackByUserId(index: number, user: CognitoUser): string {
    return user.username; // Use a unique identifier, e.g., username or user ID
  }

  // Function used to delete a user from login database and cognito user pool
  async deleteSelectedUsers() {
    // Display a message for the user to be sure that he realy want to delete the user
    if (!confirm('Are you sure you want to delete the selected users?')) {
      return;
    }

    const usernames = this.selectedUsers.map(user => user.username); // Collect usernames to delete

    try {
      this.loading = true;
      await this.apiService.deleteSelectedUser(usernames); // Call the API service
      this.selectedUsers = []; // Clear selected users

    } catch (error) {
      console.error('Error deleting users:', error);
      console.log('An error occurred while deleting users.');
    } finally {
      this.loading = false;
    }
  }

  // Function used when user click on the location of a user, it display a select with the different server that user can be in
  triggerLocationDropbox(email: string, location: string | undefined){
    if(this.activeUserEmail === email){
      this.activeUserEmail = '';
    }else{
      this.activeUserEmail = email;
    }

    if(location){
      this.selectedLocation = location;
    }
  }

  // Function trigger when user change the select of location
  async changeUserLocation(user: any){
    try{
      // Call the API function to change the location in DB
      await this.apiService.changeUserLocation(user, this.selectedLocation);

      // Display a success or error message
      this.displayLocationMessage = true;

      setTimeout(() => { // Hide the message after 3 sec
        this.displayLocationMessage = false;
        this.activeUserEmail = '';
      }, 3000);
    }
    catch (error) {
      console.error('Error deleting users:', error);
    }
  }
}
