<app-system-message class="systemMessage"></app-system-message>



<div class="container-fluid">

  <!-- LOGIN VIEW -->
  <header *ngIf="loginActive || signUpMFAActive || signUpMFAActive ||
  resetPasswordActive || forgotPasswordForm || newPasswordFormActive ||
   emailValidationActive || phoneAuthentificationActive || MFACodeRequiredActive" id="loginBanner" class="hideSmallScreen">
    <div class="avisosBanner" *ngIf="this.showMaintenanceMessage">

    </div>
    <div class="logoBanner">
        <img class="imgLogo" alt="Novaglobal" src="/assets/logowhitee.png"/>
    </div>




  </header>
  <app-loading-element *ngIf="loading" class="container-fluido componentBelow"></app-loading-element>
  <div class="avisosBanner showOnlySmallScreen" *ngIf="this.showMaintenanceMessage && loginActive" >
    <h2 class="fontBoxTitle">{{ tituloAviso }}</h2>
    <p class="fontBoxText hideUltraSmallScreen">{{ textoAviso }}</p>
    <div class="col-12 text-center fontBoxText">
      <div  class="dateRow d-flex align-items-center justify-content-center">
        <i class="far fa-calendar-days p-2 hideIcon"></i><span> {{ plannedDate }}</span>
      </div>
    </div>
  </div>
  <!-- MFA SIGN FROM A EXTERNAL COMPONENT -->
  <section *ngIf="signUpMFAActive" class="form-group">
    <app-mfa class="container-fluido componentBelow"></app-mfa>
  </section>
  <div
    class="form-group"
    [ngClass]="{ 'd-none': !loginActive }"
    *ngIf="loginActive"
    role="main"
  >

    <article class="wrapper fadeInDown">
      <div id="formContent">
        <!-- ICON -->
        <div class="fadeIn first">
          <img class="logo" alt="Novaglobal" src="../assets/logo-white.png" alt="Novaglobal"/>
        </div>
        <div>
          <h1>{{ "cognitoLoginSignIn" | translate }}</h1>
        </div>


        <!-- LOGIN FORM -->
        <form (ngSubmit)="onSubmit()">
          <div class="form-group form-group-login fadeIn second">
            <label
              for="email"
              [ngClass]="{'form-label': user.email}"
            >
               {{ this.inputLabel | translate }}
            </label>
            <input
              type="text"
              id="email"
              [(ngModel)]="user.email"
              class="form-control"
              name="email"
              required
              minlength="4"
              #userName="ngModel"
              autocomplete="off"
              (focus)="changeOnFocus()"
              (blur)="changeOnBlur()"
            />
            <div class="password-input">
              <label
                for="password"
                [ngClass]="{'form-label-password': user.password}"
              >
              {{ 'cognitoLoginPassword' | translate }}
              </label>
              <input
                type="{{ showPassword ? 'text' : 'password' }}"
                id="password"
                [(ngModel)]="user.password"
                class="form-control"
                name="password"
                required
                minlength="4"
                #userPassword="ngModel"
                autocomplete="off"
                (focus)="changeOnFocus()"
                (blur)="changeOnBlur()"
              />
              <span
                  (click)="togglePasswordVisibility('password')"
                  (keydown.enter)="togglePasswordVisibility('password')"
                  role="button"
                  tabindex="0"
                  [attr.aria-label]="showPassword ? ('ariaLabelEyeHide' | translate) : ('ariaLabelEyeShow' | translate)"
                  >
                <i
                  id="eyeIconPassword"
                  style="color: #000;"
                  class="fa"
                  [class.fa-eye-slash]="!showPassword"
                ></i>
              </span>
            </div>
          </div>
          <input
            type="submit"
            skipLocationChange
            class="fadeIn fourth"
            value="{{ 'cognitoLoginBtnLogIn' | translate }}"
            height="30px"
          />


        </form>
        <!-- RESET PASSWORD -->
        <nav id="formFooter">
          <a
            class="underlineHover"
            href="javascript:void(0)"
            (click)="forgotPasswordClick()"
          >
            {{ "loginForgotPassword" | translate }}
          </a>
        </nav>
      </div>





    </article>
  </div>


  <!-- RESET PASSWORD USING MAIL VIEW -->
  <div
    class="form-group"
    [ngClass]="{ 'd-none': !resetPasswordActive }"
    *ngIf="resetPasswordActive"
    role="main"
  >
    <article class="wrapper fadeInDown wrapperAdds">
      <div class="fadeIn first">
        <img class="logo" src="../assets/logo-white.png" alt="Novaglobal"/>
      </div>
      <h1 #titleH1 class="text-center text-dark forgotPassword-Header">
        {{ "loginForgotPassword" | translate }}
      </h1>
      <p class="text-dark">{{ "loginForgotPasswordMessage" | translate }}</p>
      <form
        (ngSubmit)="sendUserEmailClick()"
        id="register-form"
        role="form"
        autocomplete="off"
        class="form"
        method="post"
      >
        <div class="form-group">
          <div class="input-group">
            <label for="email" class="form-label-reset-email">
              {{'loginForgotPasswordPlaceHolderEmailAddress' | translate}}
            </label>
            <input
              type="email"
              id="email"
              [(ngModel)]="email"
              class="form-control"
              name="email"
              required
              minlength="1"
              #resetPassword="ngModel"
              email="true"
              (input)="checkEmail()"
            />
          </div>
          <nav class="divBtn">
            <a class="btnA">
              <div class="btn">
                <input
                  class="inputBtn"
                  name="recover-submit"
                  value="{{
                    'loginForgotPasswordBtnResetPassword' | translate
                  }}"
                  type="submit"
                />
              </div>
            </a>
          </nav>
        </div>
      </form>
    </article>
  </div>

  <!-- RESET PASSWORD USING RESET CODE VIEW -->
  <section
    class="form-group"
    role="main"
    [ngClass]="{ 'd-none': !forgotPasswordForm }"
    *ngIf="forgotPasswordForm"
  >
    <article class="wrapper fadeInDown wrapperAdds">
      <div class="fadeIn first">
        <img class="logo" alt="Novaglobal" src="../assets/logo-white.png" alt="Novaglobal"/>
      </div>
      <h1 #titleH1 class="text-center text-dark ">
        {{ "loginForgotPasswordCreateNewPW" | translate }}
      </h1>
      <p class="text-dark">{{ "loginForgotPasswordFillFields" | translate }}</p>
      <form
        (ngSubmit)="submitPasswordReset()"
        id="register-form"
        role="form"
        autocomplete="off"
        class="form resetWithCodeView"
        method="post"
      >
        <label
          for="resetCode"
          [ngClass]="{'form-label': user.code}"
        >
          {{ 'loginForgotPasswordPlaceHolder' | translate }}
        </label>
        <input
          type="text"
          id="resetCode"
          [(ngModel)]="user.code"
          class="form-control"
          name="resetCode"
          required
          minlength="1"
          #userCode="ngModel"
          automcomplete="off"
        />
        <div class="password-input">
          <label
            for="password"
            [ngClass]="{'form-label': user.password}"
          >
            {{ 'loginForgotPasswordPlaceHolderNewPW' | translate }}
          </label>
          <input
            type="{{ showPassword ? 'text' : 'password' }}"
            id="password"
            [(ngModel)]="user.password"
            class="form-control"
            name="password"
            required
            minlength="1"
            #requestPassword="ngModel"
            (focusout)="validatePassword()"
            aria-describedby="liste"
            autocomplete="off"
          />
          <span
            (click)="togglePasswordVisibility('password')"
            (keydown.enter)="togglePasswordVisibility('password')"
            role="button"
            tabindex="0"
            [attr.aria-label]="showPassword ? ('ariaLabelEyeHide' | translate) : ('ariaLabelEyeShow' | translate)"
            >
            <i
              id="eyeIconPassword"
              style="color: #000;"
              class="fa"
              [class.fa-eye-slash]="!showPassword"
            ></i>
          </span>
        </div>
        <a>
          <h2 *ngIf="showCheckBoxTipResetPw">{{
            "loginFNewPasswordLabelPasswordRequirements" | translate
          }}</h2>
          <ul id="liste" class="popup" *ngIf="showCheckBoxTipResetPw">
            <br />
            <li class="centered-container">
              <img
                *ngIf="!charactersChecked"
                id="noChecked"
                src="assets/iconchecked.png"
                [attr.alt]="'notRespectCondition' | translate"
                class="checkSymbol"
              />
              <img
                *ngIf="charactersChecked"
                id="noChecked"
                src="assets/icochecked.png"
                [attr.alt]="'respectCondition' | translate"
                class="checkSymbol"
              />
              <span>{{
                "loginFNewPasswordPasswordRequirementsCharQtt" | translate
              }}</span>
            </li>
            <li class="centered-container">
              <img
                *ngIf="!lowerCaseChecked"
                id="noChecked"
                src="assets/iconchecked.png"
                [attr.alt]="'notRespectCondition' | translate"
                class="checkSymbol"
              />
              <img
                *ngIf="lowerCaseChecked"
                id="noChecked"
                src="assets/icochecked.png"
                [attr.alt]="'respectCondition' | translate"
                class="checkSymbol"
              />
              <span>{{
                "loginFNewPasswordPasswordRequirementsOneLowercaseLetter"
                  | translate
              }}</span>
            </li>
            <li class="centered-container">
              <img
                *ngIf="!upperCaseChecked"
                id="noChecked"
                src="assets/iconchecked.png"
                [attr.alt]="'notRespectCondition' | translate"
                class="checkSymbol"
              />
              <img
                *ngIf="upperCaseChecked"
                id="noChecked"
                src="assets/icochecked.png"
                [attr.alt]="'respectCondition' | translate"
                class="checkSymbol"
              />
              <span>{{
                "loginFNewPasswordPasswordRequirementsOneUppercaseLetter"
                  | translate
              }}</span>
            </li>
            <li class="centered-container">
              <img
                *ngIf="!oneDigitChecked"
                id="noChecked"
                src="assets/iconchecked.png"
                [attr.alt]="'notRespectCondition' | translate"
                class="checkSymbol"
              />
              <img
                *ngIf="oneDigitChecked"
                id="noChecked"
                src="assets/icochecked.png"
                [attr.alt]="'respectCondition' | translate"
                class="checkSymbol"
              />
              <span>{{
                "loginFNewPasswordPasswordRequirementsOneDigit" | translate
              }}</span>
            </li>
            <li class="centered-container">
              <img
                *ngIf="!specialCharChecked"
                id="noChecked"
                src="assets/iconchecked.png"
                [attr.alt]="'notRespectCondition' | translate"
                class="checkSymbol"
              />
              <img
                *ngIf="specialCharChecked"
                id="noChecked"
                src="assets/icochecked.png"
                [attr.alt]="'respectCondition' | translate"
                class="checkSymbol"
              />
              <span>{{
                "loginFNewPasswordPasswordRequirementsOneSpecialChar"
                  | translate
              }}</span>
            </li>
          </ul>

          <nav class="btn">
            <input
              class="inputBtn"
              name="recover-submit"
              value="{{ 'loginForgotSubmit' | translate }}"
              type="submit"
            />
          </nav>
        </a>
      </form>
    </article>
  </section>

  <!-- NEW PASSWORD VIEW -->
  <section
    class="form-group"
    role="main"
    [ngClass]="{ 'd-none': !newPasswordFormActive }"
    *ngIf="newPasswordFormActive"
  >
    <article class="wrapper fadeInDown">
      <div id="formContent">
        <div class="fadeIn first">
          <img class="logo" src="../assets/logo-white.png" alt="Novaglobal"/>

        </div>
        <br />
        <h1 #titleH1 class="text-center text-dark">{{ "loginFNewPassword" | translate }}</h1>
        <p class="text-dark">{{ "loginFNewPasswordMessage" | translate }}</p>
        <!-- You can reset your password here.-->
        <div class="panel-body">
          <form
            name="newPassForm"
            (ngSubmit)="confirmNewPasswordClick()"
            id="register-form"
            role="form"
            autocomplete="off"
            class="form"
            method="post"
          >
            <div class="form-group">
              <div class="password-input">
                <input
                  [hidden]="true"
                  type="{{ showOldPassword ? 'text' : 'password' }}"
                  id="oldPassword"
                  [(ngModel)]="user.password"
                  class="form-control font14px"
                  name="requestOldPassword"
                  placeholder="{{
                    'loginFNewPasswordPlaceHolderOldPassword' | translate
                  }}"
                  required
                  minlength="1"
                  #requestPassword="ngModel"
                />
              </div>
              <div class="password-input">
                <label for="newPassword" class="form-label-password">
                  {{ 'loginFNewPasswordPlaceHolderNewPassword' | translate }}
                </label>
                <input
                  type="{{ showPassword ? 'text' : 'password' }}"
                  id="newPassword"
                  [(ngModel)]="requestNewPassword"
                  class="form-control font14px"
                  name="requestNewPasswordfirst"
                  required
                  minlength="1"
                  #requestPassword="ngModel"
                  (focusout)="validatePassword()"
                  aria-describedby="liste"
                  autocomplete="off"
                />
                <span
                  (click)="togglePasswordVisibility('password')"
                  (keydown.enter)="togglePasswordVisibility('password')"
                  role="button"
                  tabindex="0"
                  [attr.aria-label]="showPassword ? ('ariaLabelEyeHide' | translate) : ('ariaLabelEyeShow' | translate)"
                  >
                  <i
                    #eyeIcon
                    id="eyeIconPassword"
                    class="fa"
                    [class.fa-eye-slash]="!showPassword"
                    style="color: #000;"
                  ></i>
                </span>
              </div>

              <div class="colorGreen" aria-live="polite" *ngIf="showAlertStrong">
                {{ "loginFNewPasswordAlertStrongPassword" | translate }}
              </div>
              <div class="colorRed" aria-live="polite" *ngIf="showAlertWeak">
                {{ "loginFNewPasswordAlertWeakPassword" | translate }}
              </div>
              <div class="password-input">
                <label for="newPasswordConfirmation" class="form-label-password">
                  {{ 'loginFNewPasswordPlaceHolderConfirmNewPassword' | translate }}
                </label>
                <input
                  type="{{ showPasswordConfirmation ? 'text' : 'password' }}"
                  id="newPasswordConfirmation"
                  [(ngModel)]="requestNewPasswordConfirmation"
                  class="form-control font14px"
                  name="requestNewPasswordConfirm"
                  required
                  minlength="1"
                  #requestPasswordConf="ngModel"
                  (focusout)="validatePassword(); this.confirmPasswordFocusout = true;"
                  (focusin)="this.confirmPasswordFocusout = false;  "
                  autocomplete="off"
                />
                <span
                  (click)="togglePasswordVisibility('confirmPassword')"
                  (keydown.enter)="togglePasswordVisibility('confirmPassword')"
                  role="button"
                  tabindex="0"
                  [attr.aria-label]="showPassword ? ('ariaLabelEyeHide' | translate) : ('ariaLabelEyeShow' | translate)"
                  >
                  <i
                    #eyeIconConfirm
                    id="eyeIconConfirmPassword"
                    style="color: #000;"
                    class="fa"
                    [class.fa-eye-slash]="!showPasswordConfirmation"
                  ></i>
                </span>
              </div>
              <h2 class="password-explication" *ngIf="showCheckBoxTip || requestNewPassword === ''">{{
                "loginFNewPasswordLabelPasswordRequirements" | translate
              }}</h2>
              <ul id="liste" class="popup" *ngIf="showCheckBoxTip || requestNewPassword === ''">
                <li class="container-checkbox centered-container">
                  <img
                    *ngIf="!charactersChecked"
                    id="noChecked"
                    src="assets/iconchecked.png"
                    [attr.alt]="'notRespectCondition' | translate"
                    class="checkSymbol"
                  />
                  <img
                    *ngIf="charactersChecked"
                    id="noChecked"
                    src="assets/icochecked.png"
                    [attr.alt]="'respectCondition' | translate"
                    class="checkSymbol"
                  />
                  <span>{{
                    "loginFNewPasswordPasswordRequirementsCharQtt" | translate
                  }}</span>
                </li>
                <li class="centered-container container-checkbox">
                  <img
                    *ngIf="!lowerCaseChecked"
                    id="noChecked"
                    src="assets/iconchecked.png"
                    [attr.alt]="'notRespectCondition' | translate"
                    class="checkSymbol"
                  />
                  <img
                    *ngIf="lowerCaseChecked"
                    id="noChecked"
                    src="assets/icochecked.png"
                    [attr.alt]="'respectCondition' | translate"
                    class="checkSymbol"
                  />
                  <span>{{
                    "loginFNewPasswordPasswordRequirementsOneLowercaseLetter"
                      | translate
                  }}</span>
                </li>
                <li class="centered-container container-checkbox">
                  <img
                    *ngIf="!upperCaseChecked"
                    id="noChecked"
                    src="assets/iconchecked.png"
                    [attr.alt]="'notRespectCondition' | translate"
                    class="checkSymbol"
                  />
                  <img
                    *ngIf="upperCaseChecked"
                    id="noChecked"
                    src="assets/icochecked.png"
                    [attr.alt]="'respectCondition' | translate"
                    class="checkSymbol"
                  />
                  <span>{{
                    "loginFNewPasswordPasswordRequirementsOneUppercaseLetter"
                      | translate
                  }}</span>
                </li>
                <li class="centered-container container-checkbox">
                  <img
                    *ngIf="!oneDigitChecked"
                    id="noChecked"
                    src="assets/iconchecked.png"
                    [attr.alt]="'notRespectCondition' | translate"
                    class="checkSymbol"
                  />
                  <img
                    *ngIf="oneDigitChecked"
                    id="noChecked"
                    src="assets/icochecked.png"
                    [attr.alt]="'respectCondition' | translate"
                    class="checkSymbol"
                  />
                  <span>{{
                    "loginFNewPasswordPasswordRequirementsOneDigit" | translate
                  }}</span>
                </li>
                <li class="centered-container container-checkbox">
                  <img
                    *ngIf="!specialCharChecked"
                    id="noChecked"
                    src="assets/iconchecked.png"
                    [attr.alt]="'notRespectCondition' | translate"
                    class="checkSymbol"
                  />
                  <img
                    *ngIf="specialCharChecked"
                    id="noChecked"
                    src="assets/icochecked.png"
                    [attr.alt]="'respectCondition' | translate"
                    class="checkSymbol"
                  />
                  <span>{{
                    "loginFNewPasswordPasswordRequirementsOneSpecialChar"
                      | translate
                  }}</span>
                </li>
              </ul>
              <div
                *ngIf="requestNewPassword !== requestNewPasswordConfirmation && this.confirmPasswordFocusout"
                class="customalert" aria-live="assertive"
              >
                {{ "loginFNewPasswordAlertPwDontMatch" | translate }}
              </div>
              <div class="recoverBtn">
                <!-- <a>
                  <input
                    name="recover-return"
                    class="btn btn-lg btn-primary btn-block return-btn"
                    value="{{ 'userEditButtonLabelReturn' | translate }}"
                    type="button"
                    (click)="cancelHandler()"
                  />
                </a> -->
                <a>
                  <input
                    name="recover-submit"
                    class="btn btn-lg btn-primary btn-block submit-btn"
                    value="{{ 'loginFNewPasswordBtnConfirmNewPw' | translate }}"
                    type="submit"
                  />
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </article>
  </section>
  <!-- Form to verified email adress on first signIn -->
  <section
    class="form-group"
    role="main"
    [ngClass]="{ 'd-none': !emailValidationActive }"
    *ngIf="emailValidationActive"
  >
    <article class="wrapper fadeInDown" id="formContent">
      <!-- ICON -->
      <div class="fadeIn first">
        <img class="logo" src="../assets/logo-white.png" alt="Novaglobal"/>
      </div>
      <div class="mt-3">
        <h1>{{ "validationCode" | translate }}</h1>
      </div>
      <!-- LOGIN FORM -->
      <form (ngSubmit)="emailValidate()">
        <div class="form-group fadeIn second">
          <label
          for="emailValidation">
            {{ 'validationCode' | translate }}
          </label>
          <input
            id="emailValidation"
            type="text"
            [(ngModel)]="confirmationCode"
            class="form-control"
            name="emailValidation"
            required
            autocomplete="off"
            s
          />
        </div>
        <a role="button" href="javascript:void(0);" (click)="emailValidate()">{{'sendNewCode' | translate}}</a>
        <input
          type="submit"
          skipLocationChange
          class="fadeIn fourth"
          value="{{ 'validateCode' | translate }}"
          height="30px"
        />
      </form>
    </article>
  </section>
   <!-- Form to verified phone number -->
   <section
   class="form-group"
   [ngClass]="{ 'd-none': !phoneAuthentificationActive }"
   *ngIf="phoneAuthentificationActive"
 >
   <article class="wrapper fadeInDown" id="formContent">
     <!-- ICON -->
     <nav class="fadeIn first">
       <img class="logo" src="../assets/logo-white.png" alt="Novaglobal"/>
     </nav>
     <div class="mt-3">
       <h3>{{ "validationCode" | translate }}</h3>
     </div>
     <!-- LOGIN FORM -->
     <form (ngSubmit)="phoneNumberValidate()">
       <div class="form-group fadeIn second">
         <input
           id="phoneNumberValidation"
           type="text"
           [(ngModel)]="confirmationCode"
           class="form-control"
           name="phoneNumberValidation"
           placeholder="{{ 'validationCode' | translate }}"
           required
           autocomplete="off"
           s
         />
       </div>
       <input
         type="submit"
         skipLocationChange
         class="fadeIn fourth"
         value="{{ 'validateCode' | translate }}"
         height="30px"
       />
     </form>
   </article>
 </section>

  <!-- MFA sign-in form -->
  <section
    class="form-group"
    [ngClass]="{ 'd-none': !MFACodeRequiredActive }"
    *ngIf="MFACodeRequiredActive"
  >
      <article class="wrapper fadeInDown">
      <div id="formContent">
        <!-- ICON -->
        <div class="fadeIn first">
          <img class="logo" src="../assets/logo-white.png" alt="Novaglobal"/>
        </div>
        <div class="mt-3">
          <h3>{{ "enterMFACode" | translate }}</h3>
        </div>
        <!-- LOGIN FORM -->
        <form (ngSubmit)="MFASignIn()">
          <div class="form-group">
            <div class="fadeIn second">
              <input
                id="MFACode"
                type="text"
                [(ngModel)]="MFACode"
                class="form-control"
                name="MFACode"
                placeholder="{{ 'MFACode' | translate }}"
                required
                autocomplete="off"
                s
              />
            </div>
          </div>
          <input
            type="submit"
            skipLocationChange
            class="fadeIn fourth"
            value="{{ 'cognitoLoginBtnLogIn' | translate }}"
            height="30px"
          />
        </form>
      </div>

    </article>
  </section>
</div>
