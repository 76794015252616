
<section class="ribbon-fixed">
  <div class="preventClick">

  </div>
  <article role="alert" *ngIf="systemMessageService.showRibbon && systemMessageService.selectedRibbon" tabindex="0" #box class="alert in alert-dismissible " [ngClass]="['alert-' + systemMessageService.selectedRibbon.value]">
    <div class="ribbon-content">
      <strong>{{ systemMessageService.ribbonTitle | translate}}: </strong>
      <p>{{ systemMessageService.ribbonMessage | translate}}</p>
      <button #closeButton class="ribbon-close" (click)="systemMessageService.closeRibbon()" [attr.aria-label]="'close' | translate">&times;</button>
    </div>
  </article>
</section>
