<!-- Apply dynamic classes based on the theme using ngClass directive -->
<div  class="container-fluid">

    <!-- Container with box styles and fade-in-out animation -->
    <div class="box fade-in-out" style="margin-top: 100px;">
        <div class="w3-container mb-3" style="position: relative;">
            <div class="loader" ></div>
        </div>
        <div>
          <img class="logo" [attr.alt]="'loading' | translate" src="../assets/logo-black.png">
        </div>
    </div>
</div>






