import { Component, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import { LocalStorageService } from '../local-storage.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../translation.service';
import { ThemeService } from '../service/theme.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  // Variable to store the selected value - starts with default value
  selectedLanguage: string = "FR";

  // Retrieve current language selected from local storage
  initLang: string = this.localStorageService.getItem('language');

  public currentYear: number = 0;

  options: string[] = ['FR', 'EN', 'ES'];
  isOpen: boolean = false;

  public isMouseInteraction: boolean = false;
  public isSelectOpen: boolean = false;


  // Emit event when mode changes
  @Output() mode = new EventEmitter<boolean>();

  constructor(
    private languageService: TranslateService,
    private localStorageService: LocalStorageService,
    private translate: TranslationService,
    public theme: ThemeService
  ) {
    // check the language defined from localstorage and insert as current option in Select Language Menu
    switch(this.initLang) {
      case 'fr':
        this.selectedLanguage = 'fr'; // french
        break;
      case 'en':
        this.selectedLanguage = 'en'; // english
        break;
      case 'es':
        this.selectedLanguage = 'es'; // espanish
        break;
      default:
        this.selectedLanguage = 'fr'; // french
        break;
    }
   }

  // Lifecycle hook called when the component is initialized
  ngOnInit(): void {
    this.currentYear = new Date().getFullYear();
    this.IsLoginPageActive();
    this.initializeLanguageSettings();
  }

  // Check if it is the login page and set the theme to light if it is
  IsLoginPageActive() {
    // Get the current URL of the page
    const url = window.location.href;

    // Check if the URL includes the keyword 'login'
    if (url.includes('login')) {
       // If on the login page, set the theme to 'light' in local storage
      this.localStorageService.addItem('theme', 'light')
    }
    // Return true if the URL includes 'login', otherwise return false
    return url.includes('login')
  }

  async triggerLanguageChange() {
    // Call the translateTo() method of the translate service
    // and pass the selectedLanguage as an argument
    this.translate.translateTo(this.selectedLanguage);

    // Add the selectedLanguage to the 'language' item in the localStorage
    this.localStorageService.addItem('language', JSON.parse(JSON.stringify(this.selectedLanguage)));

    // Update the initial language to the selectedLanguage
    this.initLang = this.selectedLanguage;

    // Dynamically update the <html> lang attribute
    const htmlElement = document.documentElement; // Access the <html> element
    htmlElement.lang = this.selectedLanguage; // Set the lang attribute

    this.translate.selectLangageChange();
  }

  // Separate function for language initialization
  initializeLanguageSettings() {
    // Retrieve language from localStorage or fallback to a default
    this.selectedLanguage = this.localStorageService.getItem('language') || 'en';
    this.initLang = this.selectedLanguage;

    // Set the <html> lang attribute
    const htmlElement = document.documentElement;
    htmlElement.lang = this.selectedLanguage;

    // Optionally, trigger translation based on the stored language
    this.translate.translateTo(this.selectedLanguage);
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  selectOption(option: string) {
    this.selectedLanguage = option.toLowerCase();
    this.isOpen = false;
    this.onLanguageChange();
  }

   // Déclenché par un clic de souris
   onMouseInteraction(event: Event): void {
    this.isMouseInteraction = true;
    this.isSelectOpen = true;
  }

  // Déclenché par le clavier
  onKeyboardInteraction(event: KeyboardEvent): void {
    console.log('key press: ', event);
    console.log('isSelectOpen: ', this.isSelectOpen);
    if (this.isSelectOpen) {
      this.triggerLanguageChange();
    }

    if(event.key === 'ArrowUp' || event.key === 'ArrowDown'){
      event.preventDefault();
    }

    if(event.key === 'Enter'){
      this.isSelectOpen = true;
    }
  }

  // Déclenché au changement (change)
  onLanguageChange(): void {
    console.log('mouse interact: ', this.onMouseInteraction);
    if (this.isMouseInteraction) {
      console.log('change lagage');
      this.triggerLanguageChange();
    }
  }
}
